<script lang="ts">
export default defineComponent({
  props: {
    title: {
      type: String,
      default: "No content",
    },
    description: {
      type: String,
      default: "No content",
    },
    link: {
      type: [Boolean, String],
      default: null,
    },
    linkText: {
      type: String,
      default: null,
    }
  }
})
</script>

<template>
  <div class="text-center">
    <h3 class="mt-2 text-lg font-semibold text-gray-900">
      {{ title }}
    </h3>
    <p class="mt-1 text-sm text-gray-500">
      {{ description }}
    </p>
    <div class="mt-6 max-w-xs mx-auto" v-if="link">
      <VButton :href="link" class="w-full">
        {{ linkText }}
      </VButton>
    </div>
  </div>
</template>
